<template>
  <v-app id="inspire">
    <carregando v-if="carregandoTela" :opacidade="1" />
    <v-img src="@/assets/background.jpeg" class="background-login" />
    <core-snackbar />
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="4">
          <v-card>
            <v-toolbar color="primary" flat dark>
              <v-toolbar-title> Crie sua nova senha </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 font-weight-bold">
              Insira sua nova senha
            </v-card-text>
            <v-card-text class="pa-4">
              <v-form @submit="enviar">
                <v-row>
                  <v-col>
                    <validation-observer ref="observer">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Usuário"
                        vid="senha"
                      >
                        <v-text-field
                          v-model="form.senha"
                          outlined
                          hide-details="auto"
                          label="Nova senha"
                          class="mb-4"
                          type="Password"
                          autocomplete="nope"
                          :error-messages="errors"
                          prepend-icon="mdi-account-circle"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Usuário"
                        vid="senha_confirmation"
                      >
                        <v-text-field
                          v-model="form.senha_confirmation"
                          outlined
                          type="Password"
                          hide-details="auto"
                          label="Repita sua nova senha"
                          class="mb-4"
                          autocomplete="nope"
                          :error-messages="errors"
                          prepend-icon="mdi-account-circle"
                        />
                      </validation-provider>
                    </validation-observer>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      :class="{
                        'white-text': $vuetify.theme.dark,
                      }"
                      depressed
                      outlined
                      to="/login"
                    >
                      <v-icon class="mr-2"> mdi-arrow-left-bold </v-icon>
                      Voltar
                    </v-btn>
                  </v-col>
                  <v-col class="text-left">
                    <v-btn
                      :class="{
                        'white-text': $vuetify.theme.dark,
                      }"
                      depressed
                      outlined
                      :loading="carregando"
                      type="submit"
                    >
                      <v-icon class="mr-2"> mdi-login </v-icon>
                      Enviar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import esqueceusuasenha from "@/api/esqueceusuasenha";

export default {
  name: "RedefinirSenha",

  data() {
    var url = window.location;
    return {
      carregandoTela: false,
      carregando: false,
      form: {
        senha: null,
        senha_confirmation: null,
        token: (this.token = new URLSearchParams(url.search).get("token")),
      },
    };
  },

  mounted() {
    this.loading();
  },
  methods: {
    loading() {
      this.carregandoTela = true;
      setTimeout(() => (this.carregandoTela = false), 1000);
    },

    async enviar(evento) {
      evento.preventDefault();

      try {
        this.carregando = true;

        await esqueceusuasenha.novasenha(this.form);

        this.$router.push("/login", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: "Enviado com sucesso",
          })
        );
      } catch (e) {
        console.log(e.response);
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(e.response.data.erros);
        }
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>

<style>
</style>

import axios from '@/plugins/axios'

export default {
    
    async enviaremail (dados) {
        return await axios.post('senha/email', dados)
    },

    async novasenha (dados) {
        console.log(dados)
        return await axios.post('senha/redefinir', dados)
    }
}